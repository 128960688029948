import React from 'react';

import { AxisBottom } from '@visx/axis';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { BarGroup } from '@visx/shape';
import { Tooltip, defaultStyles, withTooltip } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { t } from 'i18next';

import { FullCard } from '../types/altCard';

type CostType = 'MAIN_COST' | 'RECALL_COST';

type TooltipData = {
  key: CostType;
  cost: number;
  cardQuantity: number;
};

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  fontFamily: 'Chillax-Semibold',
  backgroundColor: '#f7f8fc',
  color: '#1E2036',
  padding: '5px',
};

type BarGroupProperties = {
  margin?: { top: number; right: number; bottom: number; left: number };
  events?: boolean;
  data: FullCard[];
};

const blue = '#aeeef8';
const beige = '#fff5ea';
const lightBlue = '#f7f8fc';
const background = 'transparent';

const defaultMargin = { top: 10, right: 0, bottom: 30, left: 0 };

const parseBiomeValue = (value: string | undefined): number => {
  const match = value?.match(/\d+/);
  return match ? Number.parseInt(match[0], 10) : 0;
};

const transformCardData = (data: FullCard[]) => {
  const allCosts = data
    .flatMap((card) => [
      parseBiomeValue(card?.MAIN_COST),
      parseBiomeValue(card?.RECALL_COST),
    ])
    .filter((cost) => !Number.isNaN(cost));

  const minCost = Math.min(...allCosts);
  const maxCost = Math.max(...allCosts);
  const costs = Array.from({ length: maxCost }, (_, index) => index + 1);
  const transformed = costs.map((cost) => ({
    cost: cost.toString(),
    MAIN_COST: data.reduce(
      (accumulator, card) =>
        accumulator +
        (parseBiomeValue(card?.MAIN_COST) === cost ? (card.quantity ?? 1) : 0),
      0
    ),
    RECALL_COST: data.reduce(
      (accumulator, card) =>
        accumulator +
        (parseBiomeValue(card?.RECALL_COST) === cost
          ? (card.quantity ?? 1)
          : 0),
      0
    ),
    quantity: data.length,
  }));

  const maxQuantity = Math.max(
    ...transformed.flatMap((d) => [d.MAIN_COST, d.RECALL_COST])
  );

  return { transformed, maxQuantity, minCost, maxCost };
};

const typeScale = scaleBand<CostType>({
  domain: ['MAIN_COST', 'RECALL_COST'],
  padding: 0.1,
});
const colorScale = scaleOrdinal<string, string>({
  domain: ['MAIN_COST', 'RECALL_COST'],
  range: [blue, lightBlue],
});

export default withTooltip<BarGroupProperties, TooltipData>(
  ({
    margin = defaultMargin,
    events = false,
    data,
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  }: BarGroupProperties & WithTooltipProvidedProps<TooltipData>) => {
    const {
      transformed: transformedData,
      maxQuantity,
      minCost,
      maxCost,
    } = transformCardData(data);

    return (
      <ParentSize>
        {({ width, height }) => {
          const legendHeight = 30;
          const svgHeight = height - legendHeight;

          const yMax = svgHeight - margin.top - margin.bottom;

          const costScale = scaleBand<string>({
            domain: Array.from({ length: maxCost - minCost + 1 }, (_, index) =>
              (minCost + index).toString()
            ),
            padding: 0.2,
          }).rangeRound([0, width - margin.left - margin.right]);

          typeScale.rangeRound([0, costScale.bandwidth()]);

          const quantityScale = scaleLinear<number>({
            domain: [0, maxQuantity],
            range: [yMax, 0],
          });

          let tooltipTimeout: number;

          return width < 10 ? (
            <></>
          ) : (
            <div className="bg-darkBlue rounded h-full w-full flex flex-col">
              <div className="flex w-full justify-center space-x-4 font-chillaxBold h-6 mt-1">
                <div className="flex items-center space-x-2">
                  <span className="block w-3 h-3 rounded-sm bg-blue" />
                  <p className="text-blue">{t('gameElements.main')}</p>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="block w-3 h-3 rounded-sm bg-white" />
                  <p className="text-white">{t('gameElements.reserve')}</p>
                </div>
              </div>
              <svg width="100%" height={svgHeight}>
                <rect
                  x={0}
                  y={0}
                  width="100%"
                  height="100%"
                  fill={background}
                />
                <Group top={margin.top} left={margin.left}>
                  <BarGroup
                    data={transformedData}
                    keys={['MAIN_COST', 'RECALL_COST']}
                    height={yMax}
                    x0={(d) => d.cost}
                    x0Scale={costScale}
                    x1Scale={typeScale}
                    yScale={quantityScale}
                    color={colorScale}
                  >
                    {(barGroups) =>
                      barGroups.map((barGroup) => (
                        <Group
                          key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                          left={barGroup.x0}
                        >
                          {barGroup.bars.map((bar) => (
                            <rect
                              key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                              x={bar.x}
                              y={bar.y}
                              width={bar.width}
                              height={bar.height}
                              fill={bar.color}
                              rx={4}
                              onClick={() => {
                                if (!events) return;
                                const { key, value } = bar;
                                alert(JSON.stringify({ key, value }));
                              }}
                              onMouseLeave={() => {
                                tooltipTimeout = globalThis.setTimeout(() => {
                                  hideTooltip();
                                }, 300) as unknown as number;
                              }}
                              onMouseMove={() => {
                                if (tooltipTimeout)
                                  clearTimeout(tooltipTimeout);
                                const top = bar.y + margin.top;
                                const left = bar.x + bar.width + margin.left;
                                showTooltip({
                                  tooltipData: {
                                    key: bar.key,
                                    cost: barGroup.index + 1,
                                    cardQuantity: bar.value,
                                  },
                                  tooltipTop: top,
                                  tooltipLeft: left,
                                });
                              }}
                            />
                          ))}
                        </Group>
                      ))
                    }
                  </BarGroup>
                </Group>
                <AxisBottom
                  top={yMax + margin.top}
                  scale={costScale}
                  stroke={beige}
                  tickStroke={beige}
                  hideAxisLine
                  tickLabelProps={{
                    fill: beige,
                    fontSize: 11,
                    textAnchor: 'middle',
                  }}
                />
              </svg>

              {tooltipOpen && tooltipData && (
                <Tooltip
                  top={tooltipTop}
                  left={tooltipLeft}
                  style={tooltipStyles}
                >
                  <div>
                    {tooltipData.cardQuantity} {t('gameElements.cards')}
                  </div>
                  <small>
                    {tooltipData.key === 'MAIN_COST'
                      ? t('deck.manaHandCost', {
                          cost: tooltipData.cost,
                        })
                      : t('deck.manaRecallCost', {
                          cost: tooltipData.cost,
                        })}
                  </small>
                </Tooltip>
              )}
            </div>
          );
        }}
      </ParentSize>
    );
  }
);
