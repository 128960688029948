import { isUnique } from './cardUtilities';
import { DBB_SEPARATOR } from '../constants/constants';
import { BasicCard, FullCard } from '../types/altCard';
import { CardStatsInfo } from '../types/card';
import { TradeListCardAPI } from '../types/cardApi';

export const formatCardsFriend = (
  cards: TradeListCardAPI[]
): CardStatsInfo[] => {
  return cards
    .filter((card) => !isUnique(card.reference))
    .map((card: TradeListCardAPI) => ({
      quantity: card.quantity,
      inMyCollection: card.inMyCollection,
      reference: card.reference,
    }));
};

export const formatTextForDB = (input: string): string => {
  return input
    .split('\n')
    .map((line) => line.replaceAll(/\s+/g, ''))
    .join(';');
};

const consolidateCards = (
  cards: {
    reference: string;
    count?: number;
    quantity?: number;
    inMyCollection?: number;
  }[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getQuantity: (card: any) => number
): Record<string, number> => {
  const cardMap: Record<string, number> = {};
  for (const card of cards) {
    const quantity = getQuantity(card);
    if (cardMap[card.reference]) {
      cardMap[card.reference] += quantity;
    } else {
      cardMap[card.reference] = quantity;
    }
  }
  return cardMap;
};

export const createTextFromBasicCards = (cards: BasicCard[]): string => {
  const cardMap = consolidateCards(cards, (card) => card.count ?? 1);
  return Object.entries(cardMap)
    .map(([reference, quantity]) => `${quantity} ${reference}`)
    .join('\n');
};

export const createTextFromCards = (cards: FullCard[]): string => {
  const cardMap = consolidateCards(cards, (card) => card.quantity ?? 1);
  return Object.entries(cardMap)
    .map(([reference, quantity]) => `${quantity} ${reference}`)
    .join('\n');
};

export const createTextFromCollection = (
  cards: FullCard[],
  useQuantity?: boolean
): string => {
  const cardMap = consolidateCards(cards, (card) =>
    useQuantity ? (card.quantity ?? 1) : (card.inMyCollection ?? 1)
  );
  return Object.entries(cardMap)
    .map(([reference, quantity]) => `${quantity}${reference}`)
    .join(DBB_SEPARATOR);
};

export const createCardsFromText = (
  allCards: FullCard[],
  cardInfos: BasicCard[],
  myCards?: FullCard[],
  isRepeat?: boolean
): {
  includedCards: FullCard[];
  excludedCards: string[];
  uniqueCards: BasicCard[];
} => {
  const includedCards: FullCard[] = [];
  const excludedCards: string[] = [];
  const uniqueCards: BasicCard[] = [];

  for (const info of cardInfos) {
    const matchedCard = allCards.find(
      (card) => card.reference === info?.reference
    );
    const matchedCollectionCard =
      myCards && myCards.find((card) => card.reference === info.reference);

    if (matchedCard) {
      const quantity = myCards ? info.count || 1 : undefined;
      const inMyCollection = myCards
        ? matchedCollectionCard?.inMyCollection ||
          matchedCollectionCard?.quantity ||
          0
        : info.count || 0;
      if (isRepeat) {
        for (let index = 0; index < info.count; index++) {
          includedCards.push({
            ...matchedCard,
          });
        }
      } else {
        includedCards.push({
          ...matchedCard,
          quantity,
          inMyCollection,
        });
      }
    } else {
      if (isUnique(info?.reference)) {
        uniqueCards.push({
          reference: info.reference,
          count: info.count || 1,
        });
      } else {
        excludedCards.push(info?.reference);
      }
    }
  }

  return {
    includedCards,
    excludedCards,
    uniqueCards,
  };
};
